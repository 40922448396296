body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  position: relative;
}
